/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../../node_modules/@scandipwa/scandipwa/src/style/abstract/variables';
@import '../../../node_modules/@scandipwa/scandipwa/src/style/abstract/media';
@import '../../../node_modules/@scandipwa/scandipwa/src/style/abstract/button';
@import '../../../node_modules/@scandipwa/scandipwa/src/style/abstract/loader';
@import '../../../node_modules/@scandipwa/scandipwa/src/style/abstract/icons';
@import '../../../node_modules/@scandipwa/scandipwa/src/style/abstract/parts';

.CartItem {
    --header-color: var(--color-black);

    &-OutOfStock {
        font-weight: bold;
        margin-block-end: 5px;
        letter-spacing: 1px;
        color: rgb(208, 71, 71);
        font-weight: 600;
    }
    &-Link {
        color: inherit;
        font-weight: 400;
        align-self: center;

        &:hover {
            text-decoration: none;
        }
    }

    &_isCartOverlay:last-of-type {
        border-block-end: none;
    }

    &-Content {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        min-width: 0;
    }
    &-Wrapper {
        display: grid;
        grid-gap: 1px;
        background: var(--color-white);
        align-items: center;
        padding: 5px 3px;
        margin: 7px 0px;
        grid-template-columns: 2fr 4fr;
        @include desktop {
            border-radius: 15px;
            margin-block: 15px;
            margin-inline: 0px;
            padding: 5px 10px;
            box-shadow: 0px 0px 5px lightgray;
            // background-image: linear-gradient(222deg, #dff0fc 12%, #ffffff 12%);
        }
        @include mobile {
            grid-template-columns: 1fr 3fr !important;
            border-radius: 15px;
            margin-block: 0px;
            padding: 5px 10px;
            box-shadow: 0px 0px 10px rgb(223, 223, 223);
            // background-image: linear-gradient(222deg, #dff0fc 12%, #ffffff 12%);
            &:first-child {
                margin-top: 10px;
            }
        }
        // @media screen and (max-width: 400px) {
        //     background-image: linear-gradient(222deg, #dff0fc 15%, #ffffff 15%);
        // }
        // @media screen and (min-width: 650px) and (max-width: 811px) {
        //     background-image: linear-gradient(222deg, #dff0fc 8%, #ffffff 8%);
        // }

        &_isMobileLayout {
            row-gap: 1px;
            min-height: 80px;
        }

        &_isCart {
            & .CartItem-Title {
                @include desktop {
                    padding-inline-start: 0px;
                }
            }
        }
    }

    &-Price {
        text-align: center;

        &_isMobileLayout {
            text-align: start;
            display: flex;
            flex-direction: column;
            justify-content: flex-end;
            align-items: flex-end;
            font-weight: 400 !important;
            font-size: 13px !important;
            margin-bottom: 5px !important;
        }

        &_isPaneOpen data {
            font-size: 1.5rem;
            letter-spacing: 1px;
            font-weight: 600;
        }
    }

    &-ProductInfo {
        object-position: top;
        display: flex;
        align-items: flex-start;
        justify-content: space-between;
    }

    &-Options {
        color: var(--secondary-dark-color);
        font-size: 12px;

        @include mobile {
            padding-block-end: 12px;
        }
    }

    &-Option {
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
    }

    &-ItemLinks {
        &Wrapper {
            display: flex;
            flex-direction: column;
            color: var(--secondary-dark-color);
            font-size: 12px;
        }
    }

    &-Title {
        display: flex;
        flex-direction: column;
        justify-content: center;
        width: 100%;
        overflow: hidden;
        color: inherit;
        font-weight: 400;
        align-self: start;

        img {
            justify-content: center;
        }
        &:hover {
            text-decoration: none;
        }
        &_isMobileLayout {
            width: 90%;
        }
    }

    &-CartItemInfo {
        display: flex;
        justify-content: flex-start;
        align-items: center;
    }

    &-Weight {
        line-height: 1.6;
        margin-bottom: 0;
        margin-left: 5px;
        @include mobile {
            font-size: 12px;
        }
        @include desktop {
            font-size: 14px;
        }
    }

    &-Heading,
    &-SubHeading {
        line-height: 1.2;
        margin: 0;
        font-weight: 600;
        letter-spacing: 1px;

        @include mobile {
            font-size: 11px;
        }
        @include desktop {
            font-size: 14px;
        }
    }

    &-Heading {
        color: var(--color-black);
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
    }

    &-SubHeading {
        color: var(--cart-item-subheading-color);
    }

    &-Picture {
        width: 100px !important;
        height: 100px !important;
        align-self: flex-start;
        display: flex;
        justify-content: center;
        margin-inline: auto;
        @media screen and (max-width: 950px) {
            width: 90px;
            height: 90px;
        }
        @media screen and (min-width: 1500px) {
            width: 125px;
            height: 125px;
        }
        @include mobile {
            width: 50px !important;
            height: 50px !important;
        }

        img {
            object-position: center;
        }

        // &_isMobileLayout {
        //     width: 70px;
        //     height: 70px;
        // }
    }

    &-ProductActions {
        display: flex;
        justify-content: space-between;
        align-items: flex-end;
    }

    &-CartItemRows {
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        min-width: 0;
    }

    &-Quantity{
        letter-spacing: 1px;
        margin: 0;
        font-weight: 600;
        @include mobile{
            font-size: 12px;
        }
    }

    &-Delete {
        cursor: pointer;
        display: flex;
        z-index: 5;
        font-size: 14px;
        margin-inline-start: 8px;

        &:hover {
            color: var(--primary-base-color);

            .CloseIcon {
                fill: var(--primary-base-color);
            }
        }

        &_isMobileLayout {
            height: 12px;
            padding-inline-start: 0;
        }
    }

    &-DeleteButtonText {
        line-height: 24px;
        margin-inline-start: 4px;

        &_isMobileLayout {
            display: none;
        }

        @include tablet {
            display: none;
        }

        @include narrow-desktop {
            display: none;
        }
    }

    & &-Qty {
        // hack to obtain required specificity
        margin-block-start: 0;

        @include mobile {
            font-size: 12px;
        }

        input {
            @include mobile {
                width: 32px;
                min-width: 32px;
                background-color: transparent;
            }
        }

        button {
            cursor: pointer;
        }
    }

    &-SwipeToDeleteRightSide {
        height: 100%;
        width: 100%;
        font-weight: 600;
        color: var(--color-white);
        background-color: var(--swipe-to-delete-bg-color);
    }

    .ProductPrice {
        letter-spacing: 1px;
        @include mobile {
            font-size: 12px;
        }
        @include desktop {
            font-size: 14px;
            min-height: auto;
            margin: 0;
        }

        &-SubPrice {
            font-size: 12px;
            font-weight: 400;
        }
    }

    .CartItem-QuantityWrapper_isPaneOpen {
        // & button {
        //     @include desktop {
        //         width: 36px !important;
        //         height: 36px !important;
        //     }
        //     @include mobile {
        //         width: 20px !important;
        //         height: 20px !important;
        //     }
        // }

        .CartItem-Qty {
            letter-spacing: 1px;
            display: flex;
            justify-content: center;
            align-items: center;
            @include desktop {
                font-size: 14px;
            }
            @include mobile {
                font-size: 12px !important;
            }
            input {
                width: 32px;
                min-width: 32px;
            }
        }
    }
}
