/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../../node_modules/@scandipwa/scandipwa/src/style/abstract/variables';
@import '../../../node_modules/@scandipwa/scandipwa/src/style/abstract/media';
@import '../../../node_modules/@scandipwa/scandipwa/src/style/abstract/button';
@import '../../../node_modules/@scandipwa/scandipwa/src/style/abstract/loader';
@import '../../../node_modules/@scandipwa/scandipwa/src/style/abstract/icons';
@import '../../../node_modules/@scandipwa/scandipwa/src/style/abstract/parts';

.CartCoupon {
    @include desktop {
        max-width: 500px;
        margin-inline: auto;
    }
    @include mobile {
        width: 100%;
        padding: 5px 0;
        margin-top: 30px;
        transition: all 0.2s linear;
        max-width: 500px;
        margin-inline: auto;
    }

    @include tablet-portrait {
        width: 100%;
    }

    &_MobileViewCouponContainer {
        @include mobile {
            padding: 5px 0;
            margin-top: 30px;
            // display: grid;
            // grid-template-columns: 3fr 0.5fr;
            display: flex;
            justify-content: center;
            align-items: center;
            column-gap: 10px;
        }
    }

    &_appliedValidCoupon {
        @include mobile {
            display: block !important;
        }
    }

    &-AppliedCouponCardContainer {
        padding: 20px 20px;
        text-align: center;
        display: flex;
        align-items: center;
        justify-content: space-evenly;
        background-color: rgb(229, 239, 229);
        @include desktop {
            border-radius: 15px;
            margin-block: 10px;
            box-shadow: -2px 5px 10px rgb(220, 220, 220),
                2px 5px 10px rgb(220, 220, 220);
        }
        @include mobile {
            border-radius: 10px;
            margin: 10px;
            box-shadow: -2px 5px 10px rgb(206, 206, 206),
                2px 5px 10px rgb(206, 206, 206);
        }
    }

    &-Message {
        display: inline-block;
        margin-bottom: 0px;
        color: rgb(150, 150, 150);
        font-weight: 700;
        letter-spacing: 1px;
        @include desktop {
            font-size: 15px;
            font-weight: normal;
        }
        @include mobile {
            font-size: 12px;
            font-weight: normal;
        }
        span {
            font-weight: bolder;
            color: var(--primary-alt-base-color);
            border-bottom: 1px dashed var(--primary-alt-base-color);
            @include desktop {
                font-size: 15px;
            }
            @include mobile {
                font-size: 13px;
            }
        }
    }

    &-ButtonContainer {
        display: flex;
        @include desktop {
            column-gap: 10px;
            box-shadow: 3px 6px 10px rgba(0, 0, 0, 0.2),
                0px 0px 10px rgba(0, 0, 0, 0.1);
            border-radius: 10px;
            padding: 15px;
        }
        @include mobile {
            margin-inline: auto;
        }
    }

    &-Input {
        display: inline-block;
        @include desktop {
            width: 100%;
        }
        @include mobile {
            display: flex;
            justify-content: center;
            align-items: center;
        }
        input {
            letter-spacing: 1px;
            text-transform: uppercase;
            @include desktop {
                margin: 0;
                width: 100%;
                border-radius: 10px;
                border: none !important;
                background-color: rgb(240, 240, 240);
                text-align: center;
                font-weight: 700;
            }
            @include mobile {
                text-align: center;
                border-radius: 30px;
                border-top-right-radius: 0px;
                border-bottom-right-radius: 0px;
                font-weight: bold;
                color: var(--primary-alt-base-color);
                border-right: none !important;
                // font-size: 13px !important;
            }

            @include tablet-portrait {
                width: 100%;
            }
        }

        @include mobile {
            width: 100%;
            margin-block-start: 0;
        }

        @include tablet-portrait {
            width: 100%;
            margin-block-start: 0;
        }

        &_emptyField {
            input {
                @include mobile {
                    border-radius: 30px;
                    border: 1px solid var(--primary-light-color) !important;
                }
            }
        }
    }

    &-ButtonWrapper {
        @include mobile {
            display: flex;
            justify-content: center;
            align-items: center;
        }
    }

    &-OffersIcon {
        @include mobile {
            text-align: center;
            top: 3px;
        }
    }

    &-Button {
        @include button;
        letter-spacing: 2px;
        @include desktop {
            border-radius: 10px;
            letter-spacing: 1px;
            &:hover {
                border-radius: 10px !important;
            }
        }
        @include mobile {
            border-radius: 30px;
            margin-inline-start: 0;
            border-top-left-radius: 0px;
            border-bottom-left-radius: 0px;
            border-width: 1px !important;
        }

        @include tablet-portrait {
            width: 100%;
            border-radius: 10px;
            margin-inline-start: 0;
            margin-block-start: 10px;
        }
    }

    &-Remove {
        @include desktop {
            border-radius: 50%;
            letter-spacing: 1px;
            width: 25px;
            height: 25px;
            background-color: grey;
            &:hover {
                border-radius: 50% !important;
            }
        }
        @include mobile {
            border-radius: 50%;
            letter-spacing: 1px;
            width: 25px;
            height: 25px;
            background-color: grey;
        }
    }

    &-Title {
        font-size: 14px;
        letter-spacing: 1px;
        font-weight: normal;
        text-transform: uppercase;
        margin: 0;
        margin-block-end: 20px;
    }

    .Field {
        margin: 0;
    }
}
