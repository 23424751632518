/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../../../node_modules/@scandipwa/scandipwa/src/style/abstract/variables';
@import '../../../../node_modules/@scandipwa/scandipwa/src/style/abstract/media';
@import '../../../../node_modules/@scandipwa/scandipwa/src/style/abstract/button';
@import '../../../../node_modules/@scandipwa/scandipwa/src/style/abstract/loader';
@import '../../../../node_modules/@scandipwa/scandipwa/src/style/abstract/icons';
@import '../../../../node_modules/@scandipwa/scandipwa/src/style/abstract/parts';

/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

:root {
    --cart-page-divider-background: #d8d8d8;
    --cart-page-promo-background: #fff;
    --cart-page-table-head-background: var(--secondary-base-color);
}

.AltCart {
    margin-block-end: var(--footer-total-height);
    @include desktop {
        padding-top: 1.5em !important;
        padding: 0 15px;
    }
    @include mobile {
        --footer-totals-height: 123px;

        padding-block-end: var(--footer-totals-height);
        padding-inline: 16px;
    }

    &-Wrapper {
        @include desktop {
            display: contents;
            // grid-template-columns: 1fr 360px;
            // grid-column-gap: 30px;
            // padding: 5px 0px;
        }

        @include mobile {
            padding: 0;
            margin-top: 120px;
        }
    }

    &-EmptryCartImageWrapper {
    }

    &-EmptryCartImageWrapper {
        text-align: center;
        img {
            width: 15em;
        }
    }

    &-Empty {
        text-align: center;
        top: 100px;
        font-size: 20px;
        border-bottom: 1px solid lightgray;
        line-height: 25px;
        letter-spacing: 2px;
        font-family: "Riffic";
        padding: 5px 0;
        color: var(--primary-alt-light-color);
    }
    .CheckoutOrderSummary {
        border: none;
    }
    .OrderSummary {
        margin: 0;
    }

    &-Summary {
        @include mobile {
            inset-inline-start: 0;
            width: 100%;
        }
    }

    &-CheckoutButtons {
        padding: 12px 0;

        @include mobile {
            padding: 14px;
        }
    }

    &-OutOfStockProductsWarning {
        padding: 10px;
        display: flex;
        justify-content: center;
        background-color: var(--primary-error-color, red);
        font-size: 14px;
        font-weight: 600;
        color: var(--color-white);
        inset-inline-start: -16px;
        width: calc(100% + 32px);
    }

    &-CheckoutButton {
        width: 100%;
        text-align: center;

        @include desktop {
            margin: 10px 0;
        }
    }

    &-Promo,
    &-Total {
        min-height: 48px;

        @include mobile {
            min-height: 0;
        }
    }

    &-Promo {
        order: 1;
        background: var(--cart-page-promo-background);

        @include desktop {
            margin-block-start: 24px;
        }

        &Block {
            display: flex;
            align-items: center;
            justify-content: center;
            border: 1px solid var(--cart-page-divider-background);
            padding: 24px 12px;

            @include mobile {
                margin-block-start: 12px;
                padding: 14px;
                border: 1px solid var(--primary-divider-color);
            }
        }

        &Image {
            width: 46px;
            margin-inline-end: 6px;

            @include mobile {
                width: 36px;
                margin-inline-end: 7px;
            }
        }

        strong {
            margin: 0 5px;
        }
    }

    &-Total {
        display: flex;
        justify-content: space-between;
        font-size: 16px;
        align-items: center;
        font-weight: bold;
        padding: 12px 0;

        @include mobile {
            padding-block-start: 14px;
            padding-block-end: 0;
            padding-inline: 14px;
        }

        dd {
            text-align: end;

            span {
                display: block;
                font-size: 12px;
                font-weight: 400;
            }
        }
    }

    &-TableHead {
        display: grid;
        grid-template-columns: 3fr 2fr 1fr;
        background-color: var(--cart-page-table-head-background);
        text-transform: capitalize;
        font-size: 14px;
        font-weight: 600;
        padding: 14px 16px;
        margin-block-end: 0;

        @include mobile {
            display: none;
        }

        span {
            text-align: start;

            &:last-of-type {
                text-align: end;
            }
        }
    }

    &-Discount {
        border-block-start: 0;

        @include desktop {
            margin-block: 24px;
        }
    }

    &-Floating {
        @include desktop {
            display: flex;
            flex-direction: column;
            margin-block-start: 25px;
        }

        @include tablet {
            margin-block-start: 24px;
        }
    }

    &-Heading {
        margin-block-start: 24px;
        margin-block-end: 18px;

        @include mobile {
            display: none;
        }
    }

    &-ExpandableContentContent {
        margin-block-start: 0;
    }

    .ProductLinks-Wrapper {
        padding-inline: 0;

        @include desktop {
            padding-inline: 0px;
        }
    }

    .ProductLinks-List {
        grid-column-gap: 16px;
        z-index: 10;

        .ProductCard-ProductActions {
            display: none;
        }

        .AddToCart {
            width: 130px;
            margin-inline-end: 0;
            height: auto;
            padding: 10px 0;
            @include desktop {
                font-size: 20px;
                min-width: 40px;
                min-height: 40px;
                width: 40px;
                height: 40px;
                border-radius: 50%;
                background-color: var(--primary-alt-light-color) !important;
                border-color: var(--primary-alt-light-color) !important;
                display: flex;
                justify-content: center;
                align-items: center;
                color: white !important;
                &:hover {
                    min-width: 40px !important;
                    min-height: 40px !important;
                    width: 40px !important;
                    height: 40px !important;
                    border-radius: 50% !important;
                }
            }
        }
    }

    &-SavingTotalAmountContainer {
        background-color: var(--primary-alt-base-color);
        color: white;
        letter-spacing: 1px;
        z-index: 100;
        width: 100%;
        text-align: center;
        font-size: 15px;
        font-weight: 600;
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 10px 0;
        border-radius: 10px;
        span {
            font-size: 18px;
        }
    }

    &-OffersIcon {
        position: fixed;
        top: 13px;
        right: 20px;
        z-index: 100000;
        cursor: pointer;
    }
}

.shakeAnimationSavingStrip {
    animation: shakeTotalSavingStrip 0.1s 3 linear alternate;
    -webkit-animation: shakeTotalSavingStrip 0.1s 3 linear alternate;
}

@keyframes shakeTotalSavingStrip {
    from {
        transform: translateX(7px);
    }
    to {
        transform: translateX(-7px);
    }
}
@-webkit-keyframes shakeTotalSavingStrip {
    from {
        -webkit-transform: translateX(7px);
    }
    to {
        -webkit-transform: translateX(-7px);
    }
}
