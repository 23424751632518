/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../../node_modules/@scandipwa/scandipwa/src/style/abstract/variables';
@import '../../../node_modules/@scandipwa/scandipwa/src/style/abstract/media';
@import '../../../node_modules/@scandipwa/scandipwa/src/style/abstract/button';
@import '../../../node_modules/@scandipwa/scandipwa/src/style/abstract/loader';
@import '../../../node_modules/@scandipwa/scandipwa/src/style/abstract/icons';
@import '../../../node_modules/@scandipwa/scandipwa/src/style/abstract/parts';

/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

.AddToCart {
    width: 100%;
    padding: 0;
    vertical-align: middle;

    @include mobile{
        border-radius: 5px !important;
    }
    &:hover,
    :focus {
        --button-hover-padding: 0;
    }

    @include mobile {
        min-width: 140px;
        letter-spacing: 1px;
    }

    &_layout_list {
        width: 222px;
    }

    span {
        display: block;
        opacity: 1;
        transform: translateY(0);
        transition-property: transform, opacity;
        will-change: transform, opacity;
        transition-timing-function: ease-in;
        transition-duration: 0.25s;

        &:last-child {
            inset-inline-start: 0;
            transform: translateY(0);
        }
    }

    &_isPlaceholder {
        height: 48px;
        display: inline-block;
        padding: var(--button-padding);
        background-image: var(--placeholder-image);
        background-size: var(--placeholder-size);
        animation: var(--placeholder-animation);
        will-change: background-position;

        @include mobile {
            height: 56px;
        }
    }

    &_isLoading {
        span {
            &:first-child {
                opacity: 0;
            }

            &:last-child {
                opacity: 1;
            }
        }

        &:active {
            span {
                &:last-child {
                    opacity: 0.9;
                    transform: translateY(-120%);
                }
            }
        }
    }

    .CartIcon {
        margin-inline-end: 16px;
        padding-block-end: 20px;
        width: 20px;
        fill: var(--color-white);

        &:hover {
            fill: var(--color-white);
        }
    }
}
